import buildApphiveProcessor from '@apphiveio/controlsmanager/applicationFunctionality/buildApphiveProcessor'
import { buildAppBootLocalStorageAction } from '@apphiveio/controlsmanager/shared/applicationStateActionBuilders'
import webappFunctions from 'components/organisms/WebappContext/webappFunctions'
import { odCreatorApp } from 'firebaseConnection'
import store from 'store'
import getRenderConfig from './getRenderConfig'

const buildApplicationProcessor = (appId: string) => {
    const currentLocalDataString = window.localStorage.getItem(`dbLocalStorage:${appId}`)
    const currentLocalData = JSON.parse(currentLocalDataString || '{}')
    store.dispatch(buildAppBootLocalStorageAction(currentLocalData))
    return buildApphiveProcessor({
        firebase: {
            auth: () => odCreatorApp.auth(),
            database: () => odCreatorApp.database(),
            functions: () => odCreatorApp.functions(),
            storage: () => odCreatorApp.storage(),
            messaging: () => ({
                getToken: () => odCreatorApp.messaging().getToken(),
                registerDeviceForRemoteMessages: async () => undefined,
            }),
        },
        alert: (title, message) => {
            // eslint-disable-next-line no-alert
            window.alert(`
                ${title}
                ${message}
            `)
        },
        dispatch: (action) => store.dispatch(action),
        functionDefinitions: webappFunctions,
        getApplicationState: () => store.getState().appState,
        isInDevelopment: getRenderConfig().isRenderer,
        platformOS: 'web',
        getLanguageLocale: () => navigator.language,
    })
}

export default buildApplicationProcessor

import { createStore, Store, applyMiddleware } from 'redux'
import rootReducer, { ApphiveRendererState } from 'store/reducers'
import { ApphiveRendererAction } from 'store/actionTypes'
import syncApplicationLocalStorage from './middlewares/syncApplicationLocalStorage'
import syncApplicationDbCache from './middlewares/syncApplicationDbCache'

export type ApphiveRendererStore = Store<ApphiveRendererState, ApphiveRendererAction>

const middlewares = [
    syncApplicationLocalStorage,
    syncApplicationDbCache,

]
const store: ApphiveRendererStore = createStore(
    rootReducer,
    applyMiddleware(...middlewares),
)

export default store

import React from 'react'
import { Wrapper } from '@googlemaps/react-wrapper'

type Props = {
    children: React.ReactNode;
    googleApiKey?: string;
}

const ControlMapWrapper: React.FC<Props> = ({ children, googleApiKey }) => {
    if (googleApiKey) {
        return (
            <Wrapper apiKey={googleApiKey} libraries={['places']}>
                {children}
            </Wrapper>
        )
    }

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {children}
        </>
    )
}

export default ControlMapWrapper

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApphiveRendererState } from 'store/reducers'
import PreviewerPageSplash from '@apphiveio/controlsmanager/components/PreviewerPageSplash'
import { Processor } from '@apphiveio/controlsmanager/types/Runtime'
import AppBooter from 'components/organisms/AppBooter'
import buildApplicationProcessor from 'shared/buildApplicationProcessor'

const ProcessorAppBooter = () => {
    const [processor, setProcessor] = useState<Processor | undefined>(undefined)
    const projectId = useSelector((state: ApphiveRendererState) => state.appState.projectId)
    const appId = useSelector((state: ApphiveRendererState) => state.appState.appId)
    const buildOrders = useSelector((state: ApphiveRendererState) => state.appState.buildOrders)

    useEffect(() => {
        if (!appId) {
            return
        }
        buildApplicationProcessor(appId).then(setProcessor)
    }, [appId])

    useEffect(() => () => {
        if (!processor) {
            return
        }
        processor.destroy()
    }, [processor])

    if (!projectId || !appId || !buildOrders || !processor) {
        return <PreviewerPageSplash message="Loading app" />
    }

    return (
        <AppBooter processor={processor} buildOrders={buildOrders} />
    )
}

export default ProcessorAppBooter

import { Middleware, Dispatch } from 'redux'
import { ApphiveRendererAction } from 'store/actionTypes'
import syncAppLocalStorage from '@apphiveio/controlsmanager/applicationFunctionality/syncAppLocalStorage'
import { ApphiveRendererState } from '../reducers'

type ApphiveRendererMiddleware = Middleware<
    unknown, ApphiveRendererState, Dispatch<ApphiveRendererAction>>

const syncApplicationLocalStorage: ApphiveRendererMiddleware = (
    api,
) => (next) => (action: ApphiveRendererAction) => {
    const beforeState = api.getState()
    const result = next(action)
    const afterState = api.getState()

    syncAppLocalStorage({
        appStateBefore: beforeState.appState,
        appStateAfter: afterState.appState,
        removeItemFromLocalStorage: async (key) => window.localStorage.removeItem(key),
        setItemFromLocalStorage: async (key, value) => window.localStorage.setItem(key, value),
        actionType: action.type,
    })

    return result
}

export default syncApplicationLocalStorage

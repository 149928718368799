import { DatabaseCacheData } from '@apphiveio/controlsmanager/types/FirebaseDbCore'
import { appOverwriteDbCache } from 'store/actions/application'
import valueIsArray from '@apphiveio/controlsmanager/typeAssertions/valueIsArray'

export const bootDbCache = async (appId: string) => {
    const currentDbCacheDataString = await window.localStorage.getItem(`dbCache:${appId}`)

    const currentDbCacheData: unknown = JSON.parse(currentDbCacheDataString || '[]')

    if (!valueIsArray(currentDbCacheData)) {
        appOverwriteDbCache([])
    } else {
        appOverwriteDbCache(currentDbCacheData as DatabaseCacheData)
    }
}

export const commitDbCache = async (
    appId: string,
    dbCache?: DatabaseCacheData,
) => {
    if (!dbCache) {
        await window.localStorage.removeItem(`dbCache:${appId}`)
    } else {
        await window.localStorage.setItem(`dbCache:${appId}`, JSON.stringify(dbCache))
    }
}

import React, { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { executeAppProcess } from '@apphiveio/controlsmanager/applicationFunctionality/appProcessLauncher'
import AppRenderer from '@apphiveio/controlsmanager/AppRenderer'
import { Processor } from '@apphiveio/controlsmanager/types/Runtime'
import { DEFINITION_PROP_NAMES } from '@apphiveio/controlsmanager/types/General'
import { BuildOrders } from '@apphiveio/controlsmanager/types/Controls'
import useWindowResizeEffect from 'hooks/useWindowResizeEffect'
import { ApphiveRendererState } from 'store/reducers'
import WebappContext from 'components/organisms/WebappContext'
import webappFunctions from 'components/organisms/WebappContext/webappFunctions'
import { Frame, PreviewerFrame } from './style'

type ScreenDimentions = {
    width: number;
    height: number;
}

type Props = {
    buildOrders: BuildOrders;
    processor: Processor;
}

const AppBooter: React.VFC<Props> = ({ processor, buildOrders }) => {
    const [dimentions, setDimentions] = useState<ScreenDimentions | undefined>(undefined)
    const [executeAppProcessAlias, setExecuteAppProcessAlias] = useState<string>()
    const routeStack = useSelector((state: ApphiveRendererState) => state.appState.routing.stack)
    const loadingPageContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.loadingPageContext)
    const bottomMenuContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.bottomMenuFlagsByPage)
    const sideMenuContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.sideMenuContext)
    const alertContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.openAlert)
    const confirmAlertContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.openConfirmAlert)
    const inputDialogContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.openInputDialog)
    const cameraContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.openCameraParams)

    const calculateSize = useCallback(() => {
        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight

        if (windowWidth > windowHeight) {
            setDimentions({
                width: 339,
                height: 639,
            })
        } else {
            setDimentions({
                width: windowWidth,
                height: windowHeight,
            })
        }
    }, [])

    useWindowResizeEffect(calculateSize)

    useEffect(() => {
        const urlDecode = decodeURI(window.location.pathname.replace(/^\/|\/$/g, '')).split('/')
        if (!urlDecode.length || !buildOrders?.appProcesses) {
            return
        }

        const appProcessAlias = urlDecode[0]
        if (executeAppProcessAlias === appProcessAlias) {
            return
        }

        const appProcess = Object.entries(buildOrders.appProcesses)
            .find(([, value]) => (
                value[DEFINITION_PROP_NAMES.URL_SETTINGS]?.urlAlias === appProcessAlias
            ))

        if (!appProcess) {
            return
        }

        const urlDecodeParams = urlDecode.slice(1)
        const appProcessUrlFormat = appProcess[1][DEFINITION_PROP_NAMES.URL_SETTINGS]?.urlParams?.split('/') || []

        let urlCorrectFormat = true
        appProcessUrlFormat.forEach((param, index) => {
            if (!param.startsWith(':') && param !== urlDecodeParams[index]) {
                urlCorrectFormat = false
            }
        })

        if (!urlCorrectFormat) {
            return
        }

        const urlQueryParams = Array.from(new URLSearchParams(window.location.search).entries())
            .reduce<Partial<Record<string, string>>>((accum, [key, value]) => ({
                ...accum,
                [key]: value,
            }), {})

        const entryVars = appProcessUrlFormat.reduce((accum, param, index) => {
            if (param.startsWith(':')) {
                return {
                    ...accum,
                    [param.slice(1)]: urlDecodeParams[index],
                }
            }
            return accum
        }, urlQueryParams)

        executeAppProcess(webappFunctions)({
            functionsFromAppProcessCallbacksCallbacks: undefined,
            processor,
            entryVars,
            appProcessId: appProcess[0],
        })
        setExecuteAppProcessAlias(appProcessAlias)
    }, [processor, buildOrders?.appProcesses, executeAppProcessAlias])

    if (!dimentions) {
        return null
    }

    return (
        <Frame>
            <PreviewerFrame>
                <WebappContext
                    palette={buildOrders.palette}
                    windowHeight={dimentions.height}
                    windowWidth={dimentions.width}
                    buildOrdersFunctions={buildOrders.functions}
                    buildOrders={buildOrders}
                    processor={processor}
                    globalFontSize={buildOrders.fontSize ?? 15}
                >
                    <AppRenderer
                        buildOrders={buildOrders}
                        routeStack={routeStack}
                        loadingPageContext={loadingPageContext}
                        sideMenuContext={sideMenuContext}
                        bottomMenuContext={bottomMenuContext}
                        alertContext={alertContext}
                        cameraContext={cameraContext}
                        confirmAlertContext={confirmAlertContext}
                        inputDialogContext={inputDialogContext}
                    />
                </WebappContext>
            </PreviewerFrame>
        </Frame>
    )
}

export default AppBooter

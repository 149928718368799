/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'kU1xEYk5Pd2USnJSwxW8Uv.jpg': { uri: './images/kU1xEYk5Pd2USnJSwxW8Uv.jpg' },
'1sGeyi53xyaWiA4v9Wu5Hn.jpg': { uri: './images/1sGeyi53xyaWiA4v9Wu5Hn.jpg' },
'861u5rZabgm4vbKq5yaC9X.png': { uri: './images/861u5rZabgm4vbKq5yaC9X.png' },
'g4tovSCgqaUgTMCxK2n4Xw.png': { uri: './images/g4tovSCgqaUgTMCxK2n4Xw.png' },
'nsmmZ97Lypp1BJC4b9qbnb.png': { uri: './images/nsmmZ97Lypp1BJC4b9qbnb.png' },
'keskp9obF2mbpWHugx7URu.png': { uri: './images/keskp9obF2mbpWHugx7URu.png' },
'qLXHhNhxvSubXV1tK7FWYr.png': { uri: './images/qLXHhNhxvSubXV1tK7FWYr.png' },
'2eNZrCbM9ov41f6HnoXepA.png': { uri: './images/2eNZrCbM9ov41f6HnoXepA.png' },
'bAFiLBEktWG1Y4rHwDaxTU.png': { uri: './images/bAFiLBEktWG1Y4rHwDaxTU.png' },
'd8PtisNXYDH1MJ7ommZF7J.png': { uri: './images/d8PtisNXYDH1MJ7ommZF7J.png' },
'8Uj9YEGWhmf4kkXyoW3PaK.png': { uri: './images/8Uj9YEGWhmf4kkXyoW3PaK.png' },
'sugRHSB9M1x9P1ei53y6n1.png': { uri: './images/sugRHSB9M1x9P1ei53y6n1.png' },
'tytCfTFWMJ45QQQ2scSpL3.png': { uri: './images/tytCfTFWMJ45QQQ2scSpL3.png' },
'1TJtY6MdknF2fWjTyC49wY.png': { uri: './images/1TJtY6MdknF2fWjTyC49wY.png' },
'd7U3ipdKAdgano7YHbYFQa.png': { uri: './images/d7U3ipdKAdgano7YHbYFQa.png' },
'4QpBUCxmDtNBrp2Mbt5CXR.png': { uri: './images/4QpBUCxmDtNBrp2Mbt5CXR.png' },
'5yoCepPbk3xbJM2TeJTxJ7.png': { uri: './images/5yoCepPbk3xbJM2TeJTxJ7.png' },
'fhuVpp13BBgTVAiLHhUvXR.png': { uri: './images/fhuVpp13BBgTVAiLHhUvXR.png' },
'h5uHm7BQqeVsbgKKz3uiTY.png': { uri: './images/h5uHm7BQqeVsbgKKz3uiTY.png' }
}

export default imageStaticSourcesByFileName

import styled from 'styled-components/macro'

export const CameraWrapper = styled.div`
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    height: 100%;
`

export const Camera = styled.video`
    width: 100%;
    height: 100%;
`

export const PermissionMessage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

import React, { useEffect, useRef } from 'react'
import {
    TextInput,
} from 'react-native-web'
import { TextInputMask } from 'react-native-masked-text'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'

const ControlInputMask: AppAtomRenders['TextInputMask'] = ({
    editable,
    isFocused,
    type,
    options,
    checkText,
    placeholder,
    multiline,
    maxLength,
    placeholderTextColor,
    value,
    secureTextEntry,
    style,
    keyboardType,
    returnKeyType,
    blurOnSubmit,
    onSubmitEditing,
    onBlur,
    onFocus,
    onChangeText,
}) => {
    const inputRef = useRef<typeof TextInput | null>(null)

    useEffect(() => {
        if (isFocused && inputRef.current) {
            inputRef.current.focus()
        }
    }, [isFocused])

    return (
        <TextInputMask
            refInput={(updatedRef) => { inputRef.current = updatedRef }}
            type={type}
            options={options}
            checkText={checkText}
            placeholder={placeholder}
            multiline={multiline}
            maxLength={maxLength}
            placeholderTextColor={placeholderTextColor}
            value={value}
            editable={editable}
            secureTextEntry={secureTextEntry}
            style={style}
            keyboardType={keyboardType}
            returnKeyType={returnKeyType}
            blurOnSubmit={blurOnSubmit}
            onSubmitEditing={onSubmitEditing}
            onBlur={onBlur}
            onFocus={onFocus}
            onChangeText={onChangeText}
        />
    )
}

export default ControlInputMask

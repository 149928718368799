import type firebase from 'firebase/compat/app'

type Args = {
    firebaseApp: firebase.app.App;
    provider: firebase.auth.FacebookAuthProvider | firebase.auth.GoogleAuthProvider;
    mode: 'redirect' | 'popup';
}

const authauthLoginWithProvider = async ({
    firebaseApp,
    provider,
    mode,
}: Args) => {
    switch (provider.providerId) {
    case 'google.com':
        provider.addScope('email')
        provider.addScope('profile')
        break
    case 'facebook.com':
        provider.addScope('email')
        break
    case 'github.com':
        provider.addScope('user')
        break
    default:
        // None
    }

    firebaseApp.auth().useDeviceLanguage()

    if (mode === 'redirect') {
        await firebaseApp.auth().signInWithRedirect(provider)
        return undefined
    }

    return firebaseApp.auth().signInWithPopup(provider)
}

export default authauthLoginWithProvider

import { Middleware, Dispatch } from 'redux'

import { ApphiveRendererAction } from 'store/actionTypes'
import { commitDbCache } from 'shared/dbCacheCore'
import { ApphiveRendererState } from '../reducers'

type ApphiveRendererMiddleware = Middleware<
    unknown, ApphiveRendererState, Dispatch<ApphiveRendererAction>>

const syncApplicationDbCache: ApphiveRendererMiddleware = (
    api,
) => (next) => (action: ApphiveRendererAction) => {
    const beforeState = api.getState()
    const result = next(action)
    const afterState = api.getState()

    const { appId } = afterState.appState

    const beforeDbCacheData = beforeState.appState.dbCache
    const afterDbCacheData = afterState.appState.dbCache

    if (
        appId
        && beforeDbCacheData !== afterDbCacheData
    ) {
        commitDbCache(appId, afterDbCacheData)
    }

    return result
}

export default syncApplicationDbCache

import React, {
    useRef, useEffect, useState, useMemo, useImperativeHandle,
} from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import CameraViewer from 'components/atoms/ControlCameraViewer'
import generateImageFromCameraWithCanvas from 'shared/generateImageFromCameraWithCanvas'
import useScannerQRBarcodeFromCameraEffect from 'hooks/useScannerQRBarcodeFromCameraEffect'

const ControlCameraView: AppAtomRenders['CameraView'] = React.forwardRef(({
    style,
    onCodeReaded,
}, cmCameraRef) => {
    const cameraRef = useRef<HTMLVideoElement>(null)
    const [cameraStream, setCameraStream] = useState<MediaStream>()
    const recordedChunks = useRef<Blob[]>([])

    const mediaRecorder = useMemo(() => {
        if (cameraStream) {
            const mediaRecorder = new MediaRecorder(cameraStream, { mimeType: 'video/webm' })

            mediaRecorder.ondataavailable = (e) => {
                recordedChunks.current.push(e.data)
            }

            mediaRecorder.onstart = () => {
                recordedChunks.current = []
            }

            return mediaRecorder
        }
        return undefined
    }, [cameraStream])

    useImperativeHandle(cmCameraRef, () => ({
        decrementZoom: () => {
            throw new Error('Not implemented')
        },
        incrementZoom: () => {
            throw new Error('Not implemented')
        },
        takePhoto: async () => {
            if (!cameraRef.current) {
                throw new Error('Cannot take picture')
            }
            const uri = generateImageFromCameraWithCanvas({
                cameraElement: cameraRef.current,
            })
            if (!uri) {
                throw new Error('Cannot take picture')
            }
            return uri
        },
        startRecordingVideo: () => new Promise((resolve, reject) => {
            if (!mediaRecorder) {
                reject(new Error('Cannot start recording video'))
                return
            }

            const { state } = mediaRecorder

            if (state === 'inactive') {
                mediaRecorder.start()
            } else if (state === 'paused') {
                mediaRecorder.resume()
            }
            mediaRecorder.addEventListener('stop', () => {
                const uri = URL.createObjectURL(new Blob(recordedChunks.current, { type: 'video/mp4' }))
                resolve(uri)
            })
        }),
        stopRecordingVideo: () => {
            if (!mediaRecorder) {
                return
            }
            const { state } = mediaRecorder
            if (state === 'recording') {
                mediaRecorder.stop()
            }
        },
    }))

    useEffect(() => {
        if (cameraRef.current && cameraStream) {
            cameraRef.current.srcObject = cameraStream
            cameraRef.current.play()
        }
    }, [cameraRef, cameraStream])

    useScannerQRBarcodeFromCameraEffect(cameraRef, onCodeReaded)

    return (
        <CameraViewer
            width={style?.width}
            height={style?.height}
            ref={cameraRef}
            setCameraStream={setCameraStream}
        />
    )
})

export default ControlCameraView

import React, { useEffect, useState } from 'react'
import { Text } from 'react-native-web'
import { CameraWrapper, Camera, PermissionMessage } from './style'

type Props = {
    width?: number | string
    height?: number | string
    children?: React.ReactNode
    zoom?: number;
    setCameraStream: (stream: MediaStream) => void
}

const ControlCameraViewer = React.forwardRef<HTMLVideoElement, Props>(({
    width,
    height,
    children,
    zoom,
    setCameraStream,
}, ref) => {
    const [hasCameraPermission, setHasCameraPermision] = useState(false)

    useEffect(() => {
        const initCamera = async () => {
            if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: true, audio: true,
                })

                setCameraStream(stream)
                setHasCameraPermision(true)
            }
        }

        initCamera()
    }, [setCameraStream])
    return (
        <CameraWrapper
            style={{
                width,
                height,
            }}
        >
            <Camera
                ref={ref}
                style={{
                    objectFit: 'cover',
                    transform: zoom ? `scale(${zoom + 1})` : 'none',
                }}
            />
            {!hasCameraPermission && (
                <PermissionMessage>
                    <Text>
                        We need your permission to use your camera
                    </Text>
                </PermissionMessage>
            )}
            {children}
        </CameraWrapper>
    )
})

export default ControlCameraViewer

const audioRecorderCoreBuilder = () => {
    let recorder: MediaRecorder | undefined

    const onStartRecord = async () => {
        if (recorder) {
            throw new Error('You already have recordings started')
        }
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        recorder = new MediaRecorder(stream)
        recorder.start()
    }

    const onStopRecord = () => new Promise<string>((resolve, reject) => {
        if (recorder) {
            recorder.stop()
            recorder.stream.getAudioTracks().forEach((track) => track.stop())
            recorder.ondataavailable = (blob) => {
                recorder = undefined
                resolve(window.URL.createObjectURL(blob.data))
            }
            recorder.onerror = (event) => reject(event)
        } else {
            reject(new Error('No recordings started'))
        }
    })

    return { onStartRecord, onStopRecord }
}

export default audioRecorderCoreBuilder

import dataURItoBlob from './dataURItoBlob'

type GenerateImageFromCameraWithCanvasProps = {
    cameraElement: HTMLVideoElement;
    zoom?: number;
}

const generateImageFromCameraWithCanvas = ({
    cameraElement,
    zoom,
}: GenerateImageFromCameraWithCanvasProps) => {
    const canvas = document.createElement('canvas')
    canvas.width = cameraElement.videoWidth
    canvas.height = cameraElement.videoHeight
    const context = canvas.getContext('2d')
    if (zoom) {
        const zoomFormated = zoom + 1
        const translatePos = {
            x: ((canvas.width * zoomFormated) - canvas.width) / 2,
            y: ((canvas.height * zoomFormated) - canvas.height) / 2,
        }
        context?.translate(-translatePos.x, -translatePos.y)
        context?.scale(zoomFormated, zoomFormated)
    }

    if (!context) return undefined
    context.drawImage(cameraElement, 0, 0)
    const imgBase64 = canvas.toDataURL('image/png')
    const blob = dataURItoBlob(imgBase64)
    const uri = URL.createObjectURL(blob)

    return uri
}

export default generateImageFromCameraWithCanvas
